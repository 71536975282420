import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEventHandler, useState } from "react";
import { flexCenter, transition } from "../../../../styles/mixin";
import { Color, ZIndex } from "../../../../tokens";
import MenuList from "../MenuList";

type Props = {
  menuList: {
    menuItems: MenuItem[];
    width?: string;
    className?: string;
  };
  icon: keyof typeof IconType;
  size?: number;
  iconColor?: React.CSSProperties["color"];
  hoverColor?: React.CSSProperties["color"];
  isDisabled?: boolean;
  className?: string;
  testId?: string;
};

const IconType: { [key: string]: IconDefinition } = {
  Ellipsis: faEllipsisV,
  CaretDown: faCaretDown,
};

export type MenuItem = {
  label: string;
  subLabel?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  color?: React.CSSProperties["color"];
  isDisabled?: boolean;
  tooltipLabel?: string;
  tooltipPosition?: "Left" | "Right";
};

const MoreMenu: React.FC<Props> = ({
  size,
  menuList,
  iconColor,
  hoverColor,
  isDisabled,
  icon,
  className,
  testId,
}) => {
  const [openMenu, toggleMenu] = useState(false);
  return (
    <button
      css={iconStyle(size, openMenu, iconColor, hoverColor, isDisabled)}
      onClick={() => toggleMenu(!openMenu)}
      onBlur={() => toggleMenu(false)}
      className={className}
      data-testid={testId}
      disabled={isDisabled}
    >
      <FontAwesomeIcon icon={IconType[icon]} />
      {openMenu && (
        <MenuList
          menuItems={menuList.menuItems}
          width={menuList.width}
          className={menuList.className}
          css={MenuListStyle}
        />
      )}
    </button>
  );
};

const iconStyle = (
  size?: number,
  openMenu?: boolean,
  iconColor?: React.CSSProperties["color"],
  hoverColor?: React.CSSProperties["color"],
  isDisabled?: boolean,
) => css`
  position: relative;
  ${flexCenter}
  width: ${size ? size : 28}px;
  height: ${size ? size : 28}px;
  font-size: ${size ? `${size - 12}px` : text.size[16]};
  border-radius: 50%;
  ${transition(0.2)}
  ${isDisabled
    ? css`
        color: ${Color.Neutral.Light.Primary};
        cursor:;
      `
    : css`
        color: ${iconColor || Color.Neutral.Base.Secondary};
        background: ${openMenu
          ? iconColor || Color.Neutral.Light.Secondary
          : "transparent"};
        &:hover {
          background: ${hoverColor || Color.Neutral.Light.Secondary};
        }
      `}
`;

const MenuListStyle = css`
  position: absolute;
  text-align: left;
  top: calc(100% + 4px);
  right: 0;
  z-index: ${ZIndex.PullDown};
`;

export default MoreMenu;
