import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { IconProp as FaIcon } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, forwardRef, useCallback } from "react";
import { placeholderColor } from "../../../../styles/mixin";
import { Color, Spacing, LineHeight, RadiusSize } from "../../../../tokens";

type ChangeHanlder =
  | {
      isRHF: true;
      onChange: JSX.IntrinsicElements["input"]["onChange"];
    }
  | {
      isRHF?: false;
      onChange?: (value: string) => void;
    };

export type InputConverter = (e: ChangeEvent<HTMLInputElement>) => string;

export type InputboxProps = {
  inputType?: JSX.IntrinsicElements["input"]["type"];
  label?: string;
  endAdornment?: string;
  icon?: FaIcon;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isReadonly?: boolean;
  testId?: string;
  converter?: InputConverter;
  uncontrolled?: boolean;
} & Omit<
  JSX.IntrinsicElements["input"],
  "type" | "required" | "disabled" | "readOnly" | "onChange"
> &
  ChangeHanlder;

export const Inputbox = forwardRef<HTMLInputElement, InputboxProps>(
  function Inputbox(
    {
      id,
      inputType,
      label,
      endAdornment,
      icon,
      isRequired,
      isDisabled,
      isError,
      isReadonly,
      className,
      testId,
      onChange,
      isRHF,
      value,
      converter,
      uncontrolled,
      ...inputProps
    },
    ref,
  ) {
    const onChangeInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextValue = converter ? converter(e) : e.target.value;
        if (!onChange) return;
        if (isRHF) {
          onChange({
            ...e,
            // TODO: e.target は DOM にあたり、shallow copy するとデータが壊れてしまうため修正する
            target: { ...e.target, value: nextValue, name: e.target.name },
          });
        } else {
          onChange(nextValue);
        }
      },
      [isRHF, onChange, converter],
    );

    return (
      <div css={inputBox(isDisabled, isError)} className={className}>
        {label && (
          <label css={styledLabel} htmlFor={id}>
            {label}
          </label>
        )}
        <div css={grid(Boolean(icon), Boolean(endAdornment))}>
          {icon && <FontAwesomeIcon icon={icon} css={styledIcon} />}
          <input
            {...inputProps}
            // value に undefined を明示的に入れると uncontrollable component の警告が出てしまうため、
            // 意図的に undefined を除去している
            {...(!uncontrolled
              ? { value: value !== undefined ? value : "" }
              : {})}
            ref={ref}
            id={id}
            type={inputType}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadonly}
            onChange={onChangeInput}
            css={input(isDisabled)}
            data-testid={testId}
          />
          {endAdornment && <span css={adornmentStyle}>{endAdornment}</span>}
        </div>
      </div>
    );
  },
);

const inputBox = (isDisabled?: boolean, isError?: boolean) => css`
  position: relative;
  padding: ${Spacing[4]} ${Spacing[8]};
  background: ${Color.White};
  border: 1px solid
    ${isError ? Color.Attention.Caution.Base : Color.Neutral.Light.Primary};
  border-radius: ${RadiusSize[2]};
  font-size: ${text.size[12]};
  box-sizing: border-box;
  ${isDisabled &&
  css`
    background: ${Color.Neutral.Pale.Primary};
  `}
`;

const styledLabel = css`
  display: block;
  color: ${Color.Neutral.Base.Secondary};
  font-size: ${text.size[12]};
  line-height: 20px;
`;

const grid = (icon?: boolean, endAdornment?: boolean) => css`
  display: grid;
  grid-template-columns: ${icon && "auto"} 1fr ${endAdornment && "auto"};
  align-items: center;
`;

const styledIcon = css`
  margin-right: ${Spacing[8]};
  color: ${Color.Neutral.Base.Secondary};
  font-size: ${text.size[14]};
`;

const input = (disabled?: boolean) => css`
  ${placeholderColor(Color.Neutral.Light.Primary)}
  width: 100%;
  flex-basis: 100%;
  border: none;
  font-size: ${text.size[14]};
  line-height: 22px;
  max-height: 22px;
  padding: 0;
  background: transparent;
  &:-webkit-autofill {
    /* stylelint-disable */
    -webkit-box-shadow: 0 0 0 1000px ${Color.White} inset;
    /* stylelint-enable */
  }
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    top: -1px;
    left: -25px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  ${disabled &&
  css`
    color: ${Color.Neutral.Base.Secondary};
  `}
`;

const adornmentStyle = css`
  align-self: end;
  font-size: ${text.size[10]};
  color: ${Color.Neutral.Base.Secondary};
  line-height: ${LineHeight[180]};
`;
