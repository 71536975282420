import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { IconProp as FaIcon } from "@fortawesome/fontawesome-svg-core";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useCallback, MouseEventHandler, ReactNode } from "react";
import { placeholderColor } from "../../../../styles/mixin";
import { Color, Spacing, RadiusSize, ZIndex } from "../../../../tokens";
import MenuList, { MenuItem } from "../../navigation/MenuList";

type Props = {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  value: string;
  icon?: FaIcon;
  menuItems: MenuItem[];
  isRequired?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  onChange?: (value: string) => void;
  onDelete?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  testId?: string;
  children?: ReactNode;
};

const Autocomplete: React.FC<Props> = ({
  id,
  name,
  label,
  placeholder,
  value,
  icon,
  menuItems,
  isRequired,
  isSelected,
  isDisabled,
  isError,
  onChange,
  onDelete,
  className,
  testId,
  children,
}) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === "function") {
        onChange(e.currentTarget.value);
      }
    },
    [onChange],
  );
  return (
    <div css={wrapperStyle(isDisabled, isError)} className={className}>
      {label && (
        <label css={labelStyle} htmlFor={id}>
          {label}
        </label>
      )}
      <div css={gridStyle(Boolean(icon), isSelected)}>
        {icon && <FontAwesomeIcon icon={icon} css={iconStyle} />}
        <span onClick={() => setOpenMenu(true)}>
          <input
            id={id}
            name={name}
            type="text"
            placeholder={placeholder}
            value={value}
            required={isRequired}
            disabled={isDisabled || isSelected}
            onChange={onChangeInput}
            css={inputStyle(isDisabled)}
            data-testid={testId}
          />
        </span>
        {isSelected && onDelete && (
          <a onClick={onDelete}>
            <FontAwesomeIcon icon={faTimesCircle} css={deleteStyle} />
          </a>
        )}
      </div>
      {menuItems.length !== 0 && openMenu && (
        <div onClick={() => setOpenMenu(false)}>
          <MenuList css={menuStyle} menuItems={menuItems} width="100%" />
        </div>
      )}
      {children && (
        <div css={menuStyle} style={{ width: "100%" }}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

const wrapperStyle = (isDisabled?: boolean, isError?: boolean) => css`
  position: relative;
  padding: ${Spacing[4]} ${Spacing[8]};
  background: ${Color.White};
  border: 1px solid
    ${isError ? Color.Attention.Caution.Base : Color.Neutral.Light.Primary};
  border-radius: ${RadiusSize[2]};
  font-size: ${text.size[12]};
  box-sizing: border-box;
  ${isDisabled &&
  css`
    background: ${Color.Neutral.Pale.Primary};
  `}
`;

const labelStyle = css`
  display: block;
  color: ${Color.Neutral.Base.Secondary};
  font-size: ${text.size[12]};
  line-height: 20px;
`;

const gridStyle = (icon?: boolean, isSelected?: boolean) => css`
  display: grid;
  grid-template-columns: ${icon && "auto"} 1fr ${isSelected && "auto"};
  align-items: center;
`;

const iconStyle = css`
  color: ${Color.Neutral.Base.Secondary};
  font-size: ${text.size[14]};
  margin-right: ${Spacing[8]};
`;

const inputStyle = (isDisabled?: boolean) => css`
  ${placeholderColor(Color.Neutral.Light.Primary)}
  width: 100%;
  flex-basis: 100%;
  border: none;
  font-size: ${text.size[14]};
  line-height: 22px;
  padding: 0;
  background: transparent;
  overflow: scroll;
  &:-webkit-autofill {
    /* stylelint-disable */
    -webkit-box-shadow: 0 0 0 1000px ${Color.White} inset;
    /* stylelint-enable */
  }
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    top: -1px;
    left: -25px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  ${isDisabled &&
  css`
    color: ${Color.Neutral.Base.Secondary};
  `}
`;

const deleteStyle = css`
  color: ${Color.Neutral.Base.Secondary};
  font-size: ${text.size[14]};
  margin-left: ${Spacing[4]};
  cursor: pointer;
`;

const menuStyle = css`
  position: absolute;
  top: calc(100% + 4px);
  left: -1px;
  max-height: 160px;
  overflow: scroll;
  z-index: ${ZIndex.PullDown};
`;

export default Autocomplete;
