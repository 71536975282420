import { z } from "zod";

// 10進数のみ(符号なし)
// - zDecimal // string型として推論する場合
// - z.number().and(z.preprocess(String, zDecimal.transform(Number))) // nubmer型として推論する場合
export const zDecimal = z
  .string()
  .regex(/^((([1-9]\d*|0)\.\d+|[1-9]\d*)|0)?$/, "有効な数値を入力してください");

// 10進数のみ(符号つき)
export const zSignedDecimal = z
  .string()
  .regex(
    /^([+-]?(([1-9]\d*|0)\.\d+|[1-9]\d*)|0)?$/,
    "有効な数値を入力してください",
  );
