/** Validation Error の文言を生成するための関数 */
export const requiredErrorMessageErrorMap =
  (label: string, isSelectMode = false) =>
  () => ({
    message: `${label}を${isSelectMode ? "選択" : "入力"}してください`,
  });

/** false となるもの（empty string, null, undefined）を undefined とみなし、
必須入力時に未入力をジャッジさせるために利用します */
export const falsyToUndefined = (value: unknown) => {
  if (!value) {
    return undefined;
  }
  return value;
};

/**
 * DOM の value から取得した値は string となるため、それを Number に変換する関数
 * @param value
 * @returns {number | undefined}
 */
export const stringToNumber = (value: unknown, isOptional = false) => {
  if (
    typeof value === "undefined" ||
    (typeof value === "string" && value.length <= 0)
  ) {
    return undefined;
  }

  const result = Number(value);

  // 本来、isNaN はそのまま return したほうが良かったが、現状は握りつぶしてしまっていて
  // 任意項目の number に文字列を入れてもうまくエラーが表示されないので、
  // 暫定的に isOptional を設けて対応しています。
  if (isOptional) {
    return result;
  }

  return !isNaN(result) ? result : undefined;
};

export const nonEmptyStringToNumberOrNaN = (value: unknown) => {
  if (
    typeof value === "undefined" ||
    (typeof value === "string" && value.length <= 0)
  ) {
    return undefined;
  }

  return Number(value);
};
