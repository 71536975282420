import { useEffect, useMemo } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
  UseControllerReturn,
  useFormContext,
} from "react-hook-form";
import { Inputbox, InputboxProps } from "./base";

type OmitBasePropKeys =
  | keyof UseControllerReturn["field"]
  | keyof UseControllerProps
  | "isError"
  | "ref"
  | "isRHF";

export type RHFInputboxProps<FV extends FieldValues> = Omit<
  InputboxProps,
  OmitBasePropKeys
> &
  UseControllerProps<FV>;

export function RHFInputbox<FV extends FieldValues>({
  name,
  control,
  ...props
}: RHFInputboxProps<FV>) {
  const { field, fieldState } = useController({ name, control });
  const { unregister } = useFormContext();

  const isError = useMemo(() => {
    return !!fieldState.error;
  }, [fieldState.error]);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, [unregister, name]);

  return <Inputbox {...props} {...field} isError={isError} isRHF />;
}
