import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { IconProp as FaIcon } from "@fortawesome/fontawesome-svg-core";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, forwardRef, useCallback } from "react";
import { RadiusSize, Color, Spacing } from "../../../../tokens";

export const DEFAULT_OPTION_VALUE = "DEFAULT_OPTION_VALUE";

type ChangeHanlder =
  | {
      isRHF: true;
      onChange: JSX.IntrinsicElements["select"]["onChange"];
      resetValue: () => void;
    }
  | {
      isRHF?: false;
      onChange?: (value: string) => void;
      resetValue?: undefined;
    };

export type SelectboxProps = {
  options: Option[];
  optionDefaultText: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  label?: string;
  icon?: FaIcon;
  testId?: string;
  isRHF?: boolean;
  hideDefaultOption?: boolean;
} & Omit<
  JSX.IntrinsicElements["select"],
  "required" | "disabled" | "onChange"
> &
  ChangeHanlder;

export type Option = {
  label: string;
  value: string;
  isSelected?: boolean;
  isDisabled?: boolean;
};

export const Selectbox = forwardRef<HTMLSelectElement, SelectboxProps>(
  function Selectbox(
    {
      id,
      options,
      optionDefaultText,
      value,
      isRequired,
      isDisabled,
      isError,
      label,
      icon,
      className,
      testId,
      onChange,
      isRHF,
      resetValue,
      hideDefaultOption,
      ...selectProps
    },
    ref,
  ) {
    const selectedValue = value ?? DEFAULT_OPTION_VALUE;

    const onChangeInput = useCallback(
      (e: ChangeEvent<HTMLSelectElement>) => {
        if (!onChange) return;
        if (isRHF) {
          if (e.target.value === DEFAULT_OPTION_VALUE) {
            resetValue();
          } else {
            onChange(e);
          }
        }

        if (!isRHF) {
          if (e.target.value === DEFAULT_OPTION_VALUE) {
            onChange("");
          } else {
            onChange(e.currentTarget.value);
          }
        }
      },
      [isRHF, onChange, resetValue],
    );

    return (
      <div css={selectbox(isDisabled, isError)} className={className}>
        {label && (
          <label css={styledLabel} htmlFor={id}>
            {label}
          </label>
        )}
        <div css={selectboxInner}>
          {icon && <FontAwesomeIcon icon={icon} css={styledIcon} />}
          <select
            {...selectProps}
            ref={ref}
            id={id}
            disabled={isDisabled}
            required={isRequired}
            css={select(isDisabled)}
            value={selectedValue}
            onChange={onChangeInput}
            data-testid={testId}
          >
            {!hideDefaultOption && (
              <option value={DEFAULT_OPTION_VALUE}>{optionDefaultText}</option>
            )}
            {options?.map((option: Option, index) => {
              return (
                <option
                  key={index}
                  value={option.value}
                  selected={option.isSelected}
                  disabled={option.isDisabled}
                >
                  {option.label}
                </option>
              );
            })}
          </select>
          <FontAwesomeIcon css={selectArrowIcon} icon={faSortDown} />
        </div>
      </div>
    );
  },
);

const styledLabel = css`
  display: block;
  color: ${Color.Neutral.Base.Secondary};
  font-size: ${text.size[12]};
  line-height: 20px;
`;

const styledIcon = css`
  margin-left: ${Spacing[8]};
  color: ${Color.Neutral.Base.Secondary};
`;

const selectbox = (isDisabled?: boolean, isError?: boolean) => css`
  background: ${isDisabled ? Color.Neutral.Light.Secondary : Color.White};
  color: ${isDisabled
    ? Color.Neutral.Base.Secondary
    : Color.Neutral.Base.Primary};
  border: 1px solid
    ${isError ? Color.Attention.Caution.Base : Color.Neutral.Light.Primary};
  border-radius: ${RadiusSize[2]};
  font-size: ${text.size[12]};
  box-sizing: border-box;
  cursor: ${isDisabled ? "not-allowed" : "pointer"};
  padding: ${Spacing[4]} ${Spacing[8]};
`;

const selectboxInner = css`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const select = (isDisabled?: boolean) => css`
  width: 100%;
  border: none;
  appearance: none;
  border-radius: 0;
  outline: none;
  font-size: ${text.size[14]};
  line-height: 22px;
  cursor: ${isDisabled ? "not-allowed" : "pointer"};
  background-color: transparent;
  padding-right: ${Spacing[16]};
`;

const selectArrowIcon = css`
  position: absolute;
  right: 2px;
  bottom: 7px;
`;
