import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseControllerProps,
  UseControllerReturn,
} from "react-hook-form";
import { Selectbox, SelectboxProps } from "./base";

type OmitBasePropKeys =
  | keyof UseControllerReturn["field"]
  | keyof UseControllerProps
  | "isError"
  | "ref"
  | "isRHF";

export type RHFSelectboxProps<
  FV extends FieldValues,
  N extends FieldPath<FV>,
> = {
  onChange?: (nextState: FieldPathValue<FV, N>) => void;
} & Omit<SelectboxProps, OmitBasePropKeys> &
  UseControllerProps<FV, N>;

export function RHFSelectbox<FV extends FieldValues, N extends FieldPath<FV>>({
  name,
  control,
  onChange,
  ...props
}: RHFSelectboxProps<FV, N>) {
  const { field, fieldState } = useController({ name, control });

  const [prevState, setPrevState] = useState<FieldPathValue<FV, N>>(
    field.value,
  );

  const _onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setPrevState(field.value);
      field.onChange(e);
    },
    [field, setPrevState],
  );

  useEffect(() => {
    if (prevState === field.value) return;
    onChange && onChange(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevState, field.value]);

  const resetValue = useCallback(() => {
    field.onChange("");
  }, [field]);

  const isError = useMemo(() => {
    return !!fieldState.error;
  }, [fieldState.error]);

  return (
    <Selectbox
      {...props}
      {...field}
      onChange={_onChange}
      resetValue={resetValue}
      isError={isError}
      isRHF
    />
  );
}
